<template>
  <a-row type="flex" class="iss-wrapper">
    <a-col flex="256px" class="iss-overflow">
      <iss-fields />
    </a-col>

    <a-col
      flex="1"
      class="iss-design"
      :style="`background-image: url(${
        $store.state.common.bannerChart.bgImage || ''
      })`"
    >
      <iss-designer :relation-ref="attributeRef" />
    </a-col>

    <a-col flex="289px" class="iss-overflow">
      <iss-attribute ref="attributeRef" />
    </a-col>
  </a-row>

  <a-space direction="vertical" class="iss-operation">
    <!--  隐藏选模板功能   -->
    <!--    <div class="operation-item">-->
    <!--      <PictureOutlined class="mb-2" />-->
    <!--      <span>选模版</span>-->
    <!--    </div>-->
    <!--  v-if="Number($store.state.common.questionnaire.publishNum) > 0"  -->
    <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="handleSave">
      <template #title>
        <span>删减内容会导致内容丢失，请仔细检查。</span><br />
        <span>您是否确认发布？</span>
      </template>
      <div class="operation-item">
        <SendOutlined class="mb-2" />
        <span>发布</span>
      </div>
    </a-popconfirm>

    <!--    <div class="operation-item" @click="handleSave" v-else>-->
    <!--      <SendOutlined class="mb-2" />-->
    <!--      <span>发布</span>-->
    <!--    </div>-->
    <!--   -->
    <div class="operation-item" @click="handlePreview">
      <EyeOutlined class="mb-2" />
      <span>预览</span>
    </div>
  </a-space>

  <a-modal
    v-model:visible="visible"
    width="305px"
    title="发布成功！"
    ok-text="关闭"
    :maskClosable="false"
    :closable="false"
    centered
    :cancel-button-props="{ style: 'display:none' }"
    @ok="handleOk"
  >
    <div class="iss-result">
      <div class="result-qrcode">
        <vue-qr :text="visitLink" :margin="0" :size="100" />
      </div>
      <a-space>
        <a-button
          type="primary"
          ghost
          size="small"
          @click="handleClickByCopyLink"
        >
          复制链接
        </a-button>
        <a-button
          type="primary"
          ghost
          size="small"
          @click="handleClickByOpenLink"
        >
          直接打开
        </a-button>
      </a-space>
    </div>
  </a-modal>

  <!--  预览弹窗  -->
  <div class="preview-modal" ref="previewModal">
    <a-modal
      v-model:visible="preview"
      :closable="false"
      :getContainer="() => $refs.previewModal"
      width="100%"
      wrapClassName="full-modal"
      :bodyStyle="
        previewType
          ? `background: url(${
              $store.state.common.bannerChart.bgImage || ''
            }); width: 100%; background-color: #f0f5ff;`
          : ''
      "
      :footer="null"
      :destroyOnClose="true"
      @ok="closePreview"
    >
      <template #title>
        <div ref="modalTitleRef" style="width: 100%">
          <a-space>
            <span style="cursor: default">预览</span>
          </a-space>
          <a-space>
            <span
              :class="!previewType ? 'type-item type-item-active' : 'type-item'"
              @click="changePreview"
              style="cursor: pointer"
            >
              <MobileOutlined />
              <span>手机预览</span>
            </span>
            <span
              :class="previewType ? 'type-item type-item-active' : 'type-item'"
              @click="changePreview"
              style="cursor: pointer"
            >
              <DesktopOutlined />
              <span>电脑预览</span>
            </span>
          </a-space>
          <a-space>
            <a-button @click="closePreview" style="cursor: pointer"
              >关闭</a-button
            >
          </a-space>
        </div>
      </template>

      <div class="comPreview" v-if="previewType">
        <IssPreview></IssPreview>
      </div>

      <div class="mobile" v-else>
        <IssPreview></IssPreview>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Col, Row, Space, Popconfirm, message } from 'ant-design-vue';
import IssFields from './components/fields';
import IssDesigner from './components/designer';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { copyText } from '@/utils';
import IssAttribute from './components/attribute';
import { SendOutlined, EyeOutlined } from '@ant-design/icons-vue';
import activityCenterApi from '@/api/activityCenter';
import VueQr from 'vue-qr/src';
import { validHttp } from '@/utils/validation';
import { local } from '@/utils/storage';
import IssPreview from './components/preview';

export default {
  name: 'index',
  components: {
    ACol: Col,
    ARow: Row,
    IssFields,
    IssDesigner,
    IssAttribute,
    ASpace: Space,
    APopconfirm: Popconfirm,
    SendOutlined,
    VueQr,
    IssPreview,
    EyeOutlined,
  },
  setup() {
    const attributeRef = ref();
    const store = useStore();
    const router = useRouter();
    const visible = ref(false);
    const preview = ref(false);
    const previewType = ref(true);
    const activityId = store.state.common.activityId;

    const visitLink = `${process.env.VUE_APP_FORM_CLIENT_URL}/issmart-activity-center/events/${store.state.account.tenant}/${activityId}`;
    return {
      attributeRef,
      visitLink,
      visible,
      preview,
      previewType,
      handleClickByCopyLink: () => {
        copyText(visitLink);
        message.success('复制链接成功！');
      },
      handleClickByOpenLink: () => window.open(visitLink, '_blank'),

      handleSave: () => {
        attributeRef.value.validate().then(() => {
          const fields = store.state.common.questionnaireFields;
          if (fields.length === 0) {
            return message.warning('请配置至少一个信息');
          }

          // 处理议程属性必填问题
          const agendaList = store.state.common.questionnaireFields.filter(
            item => item.componentType === 'AGENDA'
          );
          if (agendaList[0]?.agendaName !== '') {
            if (agendaList.length > 0) {
              for (let i = 0; i < agendaList.length; i++) {
                let list = agendaList[i].questionAnswerVoList;

                if (list.length === 0) {
                  return message.error('必须配置议程内容！');
                }
                // else if (list.length > 0) {
                //   let uniqueTimeRanges = new Set(); // 使用 Set 来存储已经出现过的时间范围
                //   // filteredS 数组中将只包含去重后的元素。
                //   let filteredS = [];
                //
                //   for (let event of list) {
                //     let timeRange = event.startTime + '-' + event.endTime;
                //
                //     if (!uniqueTimeRanges.has(timeRange)) {
                //       // 如果 Set 中不包含这个时间范围
                //       uniqueTimeRanges.add(timeRange); // 将时间范围添加到 Set 中
                //       filteredS.push(event); // 将当前事件添加到去重后的数组中
                //     }
                //   }
                //
                //   // 拿去重的数组长度比较来判断数组是否存在相同的值
                //   if (list.length > filteredS.length) {
                //     return message.error('请勿设置相同的议程时间！');
                //   }
                // }

                // 处理议程时间结束时间小于开始时间
                for (let i = 0; i < list.length; i++) {
                  const element = list[i];
                  const startTimeDate = new Date(
                    `1970-01-01T${element.startTime}:00`
                  );
                  const endTimeDate = new Date(
                    `1970-01-01T${element.endTime}:00`
                  );

                  if (endTimeDate < startTimeDate) {
                    return message.error('议程结束时间不能小于或等于开始时间');
                  }

                  if (element.editorValue === '') {
                    return message.error('议程内容不能为空！');
                  }

                  // childGroup 数组
                  // let childGroupList = list[i].childGroup;
                  // for (let i = 0; i < childGroupList.length; i++) {
                  //
                  //   if (childGroupList[i].editorValue === '') {
                  //     return message.error('议程演讲主题不能为空！');
                  //   }
                  // }
                }

                if (!agendaList[i].startTime) {
                  return message.error('议程开始时间不能为空！');
                } else if (!agendaList[i].endTime) {
                  return message.error('议程结束时间不能为空！');
                } else {
                  continue;
                }
                // else if (!agendaList[i].name) {
                //     return message.error('议程主讲人不能为空！');
                //   }
              }
            }
          }

          // 处理嘉宾属性必填问题
          const angerList = store.state.common.questionnaireFields.filter(
            item => item.componentType === 'ANGER'
          );

          let questionAnswerVoList = angerList[0]?.questionAnswerVoList;

          if (questionAnswerVoList?.length === 0) {
            return message.info('请输入嘉宾内容！');
          }
          if (questionAnswerVoList?.length > 0) {
            for (let i = 0; i < questionAnswerVoList.length; i++) {
              if (questionAnswerVoList[i].guestsBriefly === '') {
                return message.info('嘉宾简介不能为空');
              } else if (questionAnswerVoList[i].guestsImage === '') {
                return message.info('嘉宾图片不能为空');
              } else if (questionAnswerVoList[i].guestsName === '') {
                return message.info('嘉宾姓名不能为空');
              }
            }
          }

          // 处理banner 链接

          const { bannerLink, banner, videoBanner } =
            store.state.common.bannerChart;
          if (!bannerLink && !banner) {
            return message.error('横幅图或横幅视频至少需要设置一个！');
          }

          if (bannerLink) {
            if (!validHttp(bannerLink)) {
              return message.error(
                '请输入正确的横幅视频URL地址，以http或者https开头的链接'
              );
            }
            if (!videoBanner) {
              return message.error('视频封面不能为空！');
            }
          }

          let parmas = {
            banner: store.state.common.bannerChart,
            title: store.state.common.questionnaire,
            content: store.state.common.questionnaireFields,
          };
          const { activityId, id, saveId } = store.state.common;
          if (id) {
            // 编辑
            activityCenterApi
              .updateActivityCenter('', {
                json: JSON.stringify(parmas),
                activityId: activityId,
                id: saveId ? saveId : id,
                formId: local.get('formId'),
              })
              .then(res => {
                store.commit('common/setSaveId', res.id);
                // 发布接口
                activityCenterApi
                  .updatePuhStatus('', { id: res.activityId, pubStatus: 1 })
                  .then(() => {
                    visible.value = true;
                  });
              });
          } else {
            // 新增
            activityCenterApi
              .activitySave('', {
                json: JSON.stringify(parmas),
                activityId: activityId,
                formId: local.get('formId'),
              })
              .then(res => {
                store.commit('common/setId', res.id);
                store.commit('common/setSaveId', res.id);
                // 发布接口
                activityCenterApi
                  .updatePuhStatus('', { id: res.activityId, pubStatus: 1 })
                  .then(() => {
                    visible.value = true;
                  });
              });
          }
        });
      },
      handlePreview: () => {
        preview.value = true;
      },
      closePreview: () => {
        preview.value = false;
      },
      changePreview: () => {
        previewType.value = !previewType.value;
      },
      handleOk: () => {
        visible.value = false;
        router.go(-1);
        store.commit('common/setStatus', false);
      },
    };
  },
};
</script>

<style scoped lang="less">
// 全拼预览样式

.preview-modal {
  .mobile {
    height: 768px;
    background: url('../../../../assets/images/mobilebg.png') no-repeat center
      center;
    background-size: contain;
    padding: 56px 215px 0px;
    margin: 0 auto;
    overflow-y: auto;
    margin-top: 20px;
    :deep(.main-wrapper) {
      background-color: transparent;
    }

    :deep(.main-wrapper .main-form) {
      margin-top: 10px;
    }

    :deep(.main-wrapper) {
      max-height: 676px;
      max-width: 331px;
      overflow-y: auto;
      margin-top: 0;
    }
    :deep(.main-wrapper .main-agreement) {
      margin: 10px !important;
    }
    :deep(.main-wrapper .main-form .ant-form-item) {
      padding: 10px;
    }
    :deep(.main-hover) {
      margin: 0 !important;
    }
    :deep(.ant-form-item-control) {
      .ant-row {
        @media (min-width: 1200px) {
          .ant-col-xl-12 {
            display: block;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 10px 0;
          }
        }
      }
    }

    //   写手机预览的样式
    .agenda {
      padding: 15px 12px 0px;
    }
  }

  :deep(.ant-modal-title > div) {
    font-size: 18px;
  }

  :deep(.ant-modal-title > div) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  :deep(.full-modal .ant-modal) {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  :deep(.main-wrapper .main-hover) {
    border: none;
    margin: 10px;
  }

  :deep(.full-modal .ant-modal-content) {
    display: flex;
    flex-direction: column;
    background-color: #f0f5ff;
    padding-bottom: 16px;
    min-height: 100vh;
  }

  :deep(.full-modal .ant-modal-body) {
    flex: 1;
    background: #fff;
    margin: 0 auto;
    padding: 0;
  }
  :deep(.main-wrapper) {
    max-width: 808px;
    margin-top: 10px;
  }
}

// end
.iss-wrapper {
  margin: 16px;
  .iss-overflow {
    height: calc(100vh - 90px);
    overflow-y: auto;
    background-color: #fff;
    border-radius: 8px;
  }
  .iss-design {
    margin: 0 16px;
    padding: 0 20px;
    height: calc(100vh - 90px);
    overflow-y: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: @box-shadow-base;
  }
}
.iss-operation {
  position: fixed;
  top: 170px;
  right: 330px;
  z-index: 30;
  .operation-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    width: 54px;
    border-radius: 6px;
    background: #fff;
    box-shadow: @box-shadow-base;
    opacity: 0.7;
    cursor: pointer;
    .mb-2 {
      font-size: 18px;
    }
    &:hover {
      color: @primary-color;
    }
  }
}
.iss-result {
  text-align: center;
  .result-qrcode {
    margin: 0 auto 25px auto;
    width: 100px;
    height: 100px;
  }
}

.type-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 18px;
  padding: 10px;
  :deep(.anticon) {
    font-size: 25px;
  }
}

.type-item:hover {
  background-color: #6e8af9;
  color: #fff;
}

.type-item-active {
  background-color: #6e8af9;
  color: #fff;
}
</style>
